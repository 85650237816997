import Home from "../views/home.vue";
let routes = [
  {
    path: "/",
    name: "home",
    redirect: "/home",
  },
  {
    path: "/academic_help", //指南中心
    name: "academic_help",
    component: () => import("../views/academic-headlines/index/help.vue"),
    redirect: "/help_list",
    children: [
      // 帮助首页
      {
        path: "/help_list",
        name: "help_list",
        component: () => import("../views/academic-headlines/index/list.vue"),
      },
      {
        path: "/help_main",
        name: "help_main",
        component: () =>
          import("../views/academic-headlines/index/help_main.vue"),
      },
    ],
  },
  {
    path: "/home",
    name: "home",
    redirect: "/academic-headlines",
    component: Home,
    children: [
      {
        path: "/academic-headlines", //知识头条
        name: "academic-headlines",
        redirect: "/index",
        component: () => import("../views/academic-headlines/index.vue"),
        children: [
          {
            path: "/index", //知识头条首页 wc5.6
            name: "index",
            component: () =>
              import("../views/academic-headlines/index/index.vue"),
          },
          {
            path: "/newsFlash", //快讯 wc5.6
            name: "NewsFlash",
            component: () =>
              import("../views/academic-headlines/news-flash/index.vue"),
          },
          {
            path: "/Hotlist", //热榜
            name: "Hotlist",
            component: () =>
              import("../views/academic-headlines/hot-list/index.vue"),
          },

          {
            path: "/Recommend", //推荐
            name: "Recommend",
            component: () =>
              import("../views/academic-headlines/recommend/index.vue"),
          },
          {
            path: "/Focus", //关注
            name: "Focus",
            component: () =>
              import("../views/academic-headlines/focus/index.vue"),
          },
          {
            path: "/Theme", //主题
            name: "Theme",
            component: () =>
              import("../views/academic-headlines/theme/index.vue"),
          },
          {
            path: "/themeDetail", //主题详情
            name: "ThemeDetail",
            component: () =>
              import(
                "../views/academic-headlines/theme/theme-detail/index.vue"
              ),
          },
          {
            path: "/collectArticle", //收藏文章列表
            name: "CollectArticle",
            component: () =>
              import(
                "../views/academic-headlines/focus/page-collect-article/index.vue"
              ),
          },
        ],
      },
      {
        path: "/wisdomAnswer", //众智问答
        name: "WisdomAnswer",
        redirect: "/waIndex",
        component: () => import("../views/wisdom-answer/index.vue"),
        children: [
          {
            path: "/waIndex", //问答首页
            name: "WaIndex",
            redirect: "/answerIndex",
            component: () => import("../views/wisdom-answer/index/index.vue"),
            children: [
              {
                path: "/answerIndex",
                name: "WaAnswerIndex",
                redirect: "/qaIndex",
                component: () =>
                  import("../views/wisdom-answer/index/main/index.vue"),
                children: [
                  {
                    path: "/qaIndex", //智能问答首页
                    name: "QaIndex",
                    component: () =>
                      import(
                        "../views/wisdom-answer/index/main/qa-index/index.vue"
                      ),
                  },
                  {
                    path: "/allQuestion", //问题全部
                    name: "AllQuestion",
                    component: () =>
                      import(
                        "../views/wisdom-answer/index/all-questions/index.vue"
                      ),
                  },
                  {
                    path: "/recommended", //推荐专家
                    name: "recommended",
                    component: () =>
                      import(
                        "../views/wisdom-answer/index/recommended-experts/index.vue"
                      ),
                  },
                  {
                    path: "/issues", //热点问题
                    name: "issues",
                    component: () =>
                      import(
                        "../views/wisdom-answer/index/hotspot-issues/index.vue"
                      ),
                  },
                ],
              },
              {
                path: "/answerDetail", //答案详情页
                name: "AnswerDetail",
                component: () =>
                  import("../views/wisdom-answer/answerDetail/index.vue"),
              },
              {
                path: "/askQuestion", //提问页面
                name: "AskQuestion",
                component: () =>
                  import("../views/wisdom-answer/ask-question/index.vue"),
              },
              {
                path: "/askPreview", //问题预览
                name: "AskPreview",
                component: () =>
                  import("../views/wisdom-answer/ask-preview/index.vue"),
              },
              // src\views\wisdom-answer\qqq\index.vue
              {
                path: "/searchAsk", //搜索问题
                name: "SearchAsk",
                component: () =>
                  import("../views/wisdom-answer/search-ask/index.vue"),
              },
              ////话题自动组织
              {
                path: "/automatic", //搜索问题
                name: "automatic",
                component: () =>
                  import("../views/wisdom-answer/automatic/index.vue"),
              },
            ],
          },
        ],
      },
      ////////////////////////消息通知
      {
        path: "/Notification",
        name: "Notification",
        redirect: "/service",
        component: () =>
          import(
            "../views/Personal Center/Message_notification/index.vue" ////////////////消息通知
          ),
        children: [
          {
            path: "/intelligence",
            name: "intelligence",
            component: () =>
              import(
                "../views/Personal Center/Message_notification/intelligence/index.vue" //智能问答
              ),
          },
          // 资源交易
          {
            path: "/resources",
            name: "resources",
            component: () =>
              import(
                "../views/Personal Center/Message_notification/resources/index.vue"
              ),
          },
          ///知识服务
          {
            path: "/service",
            name: "service",
            component: () =>
              import(
                "../views/Personal Center/Message_notification/service/index.vue"
              ),
          },
          // 知识加工
          {
            path: "/machining",
            name: "machining",
            component: () =>
              import(
                "../views/Personal Center/Message_notification/machining/index.vue"
              ),
          },
        ],
      },
      // 引用页面
      {
        path: "/Quote",
        name: "Quote",
        component: () =>
          import(
            "../views/wisdom-answer/index/quote/index.vue" ////////////////引用
          ),
      },
      // 引用页面
      {
        path: "/teamresponse",
        name: "teamresponse",
        component: () =>
          import(
            "../views/wisdom-answer/index/team-response/index.vue" ////////////////团队回答
          ),
      },
      //弹出框
      {
        path: "/PopUp",
        name: "PopUp",
        component: () =>
          import(
            "../views/wisdom-answer/index/team-response/Pop/index.vue" ////////////////弹出框
          ),
      },
      //正在加载中
      {
        path: "/Loading",
        name: "Loading",
        component: () =>
          import(
            "../views/wisdom-answer/index/team-response/Pop/Loading/index.vue" ////////////////弹出框
          ),
      },
      // src\views\wisdom-answer\Loading\index.vue

      {
        path: "/Answer",
        name: "Answer",
        component: () =>
          import(
            "../views/wisdom-answer/index/quote/answer/index.vue" ////////////////回答
          ),
        // src\views\wisdom-answer\index\quote\answer\index.vue
      },
      // src\views\Personal Center\Message_notification\index.vue
      {
        path: "/Personal", //个人中心
        name: "Personal",
        redirect: "/PCnews",
        component: () => import("../views/Personal Center/index.vue"),
        children: [
          {
            path: "/PCnews",
            name: "PCnews",
            redirect: "/Comment",
            component: () =>
              import("../views/Personal Center/PCNews/index.vue"),
            children: [
              {
                path: "/Private_letter",
                name: "Private_letter",
                component: () =>
                  import(
                    "../views/Personal Center/PCNews/Private_letter/index.vue"
                  ),
              },
              {
                path: "/Collection",
                name: "Collection",
                component: () =>
                  import(
                    "../views/Personal Center/PCNews/Collection/index.vue"
                  ),
              },
              {
                path: "/Comment",
                name: "Comment",
                component: () =>
                  import("../views/Personal Center/PCNews/Comment/index.vue"),
              },
              {
                path: "/Article",
                name: "Article",
                component: () =>
                  import("../views/Personal Center/PCNews/Article/index.vue"),
              },
              {
                // 订阅
                path: "/subscribe",
                name: "subscribe",
                component: () =>
                  import(
                    "../views/Personal Center/PCNews/Subscribe/subscribe.vue"
                  ),
              },
              {
                path: "/Put_Questions",
                name: "Put_Questions",
                component: () =>
                  import(
                    "../views/Personal Center/PCNews/Put_Questions/index.vue"
                  ),
              },
              {
                path: "/Answer",
                name: "Answer",
                component: () =>
                  import("../views/Personal Center/PCNews/Answer/index.vue"),
              },
              {
                path: "/Follow",
                name: "Follow",
                component: () =>
                  import("../views/Personal Center/PCNews/Follow/index.vue"),
              },
              {
                path: "/Offer_Reward",
                name: "Offer_Reward",
                component: () =>
                  import(
                    "../views/Personal Center/PCNews/Offer_Reward/index.vue"
                  ),
              },
            ],
          },
          // 收益查询
          {
            path: "/Revenue_inquiry",
            name: "Revenue_inquiry",
            component: () =>
              import("../views/Personal Center/Revenue_inquiry/index.vue"),
          },
          // 加工进度
          {
            path: "/Schedule",
            name: "Schedule",
            redirect: "/posted",
            component: () =>
              import("../views/Personal Center/Schedule/index.vue"),
            children: [
              ////我发布的
              {
                path: "/posted",
                name: "posted",
                component: () =>
                  import("../views/Personal Center/Schedule/posted/index.vue"),
              },
              ////我负责的
              {
                path: "/charge",
                name: "charge",
                component: () =>
                  import("../views/Personal Center/Schedule/charge/index.vue"),
              },
              ////我加工的
              {
                path: "/made",
                name: "made",
                component: () =>
                  import("../views/Personal Center/Schedule/made/index.vue"),
              },
            ],
          },
          {
            path: "/PCCollection",
            name: "PCCollection",
            component: () =>
              import("../views/Personal Center/PCCollection/index.vue"),
          },
          // 我的团队
          {
            path: "/PCmyTeam",
            name: "PCmyTeam",
            component: () =>
              import("../views/Personal Center/PCmy_team/index.vue"),
          },
          // 订阅设置
          {
            path: "/PCmySubset",
            name: "PCmySubset",
            component: () =>
              import("../views/Personal Center/SubSet/subset.vue"),
          },
          {
            path: "/PCresourxce",
            name: "PCresourxce",
            redirect: "/pcresourxce",
            component: () =>
              import("../views/Personal Center/PCresourxce/index.vue"),
            children: [
              {
                path: "/pcresourxce",
                name: "pcresourxce",
                component: () =>
                  import(
                    "../views/Personal Center/PCresourxce/pcresourxce/index.vue"
                  ),
              },
              {
                path: "/pcdownload",
                name: "pcdownload",
                component: () =>
                  import(
                    "../views/Personal Center/PCresourxce/pcdownload/index.vue"
                  ),
              },
            ],
          },
          {
            path: "/Basicinformation",
            name: "Basicinformation",
            component: () =>
              import(
                "../views/Personal Center/PCdata/Basic_information/index.vue"
              ),
          },
          {
            path: "/PCmySearch", //建库查询
            name: "PCmySearch",
            component: () =>
              import("../views/Personal Center/Database_Search/index.vue"),
          },
          {
            path: "/PCmyContract", //建库查询
            name: "PCmyContract",
            component: () =>
              import("../views/Personal Center/contract/myContract.vue"),
          },
          {
            path: "/email",
            name: "email",
            component: () =>
              import("../views/Personal Center/PCdata/changeemail/index.vue"),
          },
          {
            path: "/phone",
            name: "phone",
            component: () =>
              import("../views/Personal Center/PCdata/changephone/index.vue"),
          },
          {
            path: "/Passwordsettings",
            name: "Passwordsettings",
            component: () =>
              import(
                "../views/Personal Center/PCdata/Password_settings/index.vue"
              ),
          },
          {
            path: "/Thirdparty",
            name: "Thirdparty",
            component: () =>
              import("../views/Personal Center/PCdata/Third_party/index.vue"),
          },
        ],
      },
      {
        path: "/Resource", //资源交易
        name: "Resource",
        redirect: "/resources_index",
        component: () => import("../views/Resource_transactions/index.vue"),
        children: [
          {
            path: "/resources_index",
            name: "resources_index",
            component: () =>
              import(
                "../views/Resource_transactions/resources_index/index.vue"
              ),
          },

          {
            path: "/Resource_theme",
            name: "Resource_theme",
            component: () =>
              import(
                "../views/Resource_transactions/resources_index/Resource_theme/index.vue"
              ),
          },
          {
            path: "/Resource_transactions",
            name: "Resource_transactions",
            component: () =>
              import(
                "../views/Resource_transactions/Resource_transactions/index.vue"
              ),
          },

          {
            path: "/my_Resource",
            name: "my_Resource",
            component: () =>
              import("../views/Resource_transactions/my_Resource/index.vue"),
            children: [
              {
                path: "/myresourxce",
                name: "myresourxce",
                component: () =>
                  import(
                    "../views/Resource_transactions/my_Resource/myresourxce/index.vue"
                  ),
              },
              {
                path: "/mydownload",
                name: "mydownload",
                component: () =>
                  import(
                    "../views/Resource_transactions/my_Resource/mydownload/index.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "/ZSmachining", // 知识加工
        name: "ZSmachining",
        // redirect: "/machining",
        component: () => import("../views/machining/index.vue"),
        // children: [
        //   //首页
        // {
        //   path: "/machining",
        //   name: "machining",
        //   component: () =>
        //     import("../views/machining/index.vue"),
        // },
        // ]
      },
      {
        path: "/Achievement", // 成果展示
        name: "Achievement",
        // redirect: "/machining",
        component: () =>
          import("../views/Personal Center/Achievement/index.vue"),
      },
      {
        path: "/complaint", // 成果展示-投诉
        name: "complaint",
        // redirect: "/machining",
        component: () =>
          import("../views/Personal Center/Achievement/complaint/index.vue"),
      },

      //发布纸质资料数字化加工
      {
        path: "/digitization",
        name: "digitization",
        component: () => import("../views/machining/publish/digitization.vue"),
      },
      //发布词条加工
      {
        path: "/releaseEntry",
        name: "releaseEntry",
        component: () => import("../views/machining/publish/releaseEntry.vue"),
      },
      //发布数字产品加工
      {
        path: "/digitalProducts",
        name: "digitalProducts",
        component: () =>
          import("../views/machining/publish/digitalProducts.vue"),
      },
      //纸质资料数字化加工录入
      {
        path: "/entering",
        name: "entering",
        component: () => import("../views/machining/publish/entering.vue"),
      },

      //任务详情
      {
        path: "/taskDetail",
        name: "taskDetail",
        component: () => import("../views/machining/detail/taskDetail.vue"),
      },
      //任务划分
      {
        path: "/taskDivide",
        name: "taskDivide",
        component: () => import("../views/machining/detail/taskDivide.vue"),
      },

      //任务划分
      {
        path: "/taskDivideAffirm",
        name: "taskDivideAffirm",
        component: () =>
          import("../views/machining/detail/taskDivideAffirm.vue"),
      },

      {
        path: "/Knowledge_Processing", //知识服务
        name: "Knowledge",
        redirect: "/Knowledge_index",
        component: () => import("../views/Knowledge-processing/index.vue"),
        children: [
          //首页
          {
            path: "/Knowledge_index",
            name: "Knowledge_index",
            component: () =>
              import("../views/Knowledge-processing/Knowledge_index/index.vue"),
          },
          //简介
          {
            path: "/Knowledge_introduction",
            name: "Knowledge_introduction",
            component: () =>
              import(
                "../views/Knowledge-processing/Knowledge_introduction/index.vue"
              ),
          },
          //付费下载列表
          {
            path: "/payDoload",
            // name: "payDoload",
            component: () =>
              import(
                "../views/Knowledge-processing/Knowledge_serve_list/payDoload.vue"
              ),
          },
          //上传
          {
            path: "/Knowledge_down",
            name: "Knowledge_down",
            component: () =>
              import("../views/Knowledge-processing/Knowledge_down/index.vue"),
          },
          //单条数据详情
          {
            path: "/dataInfo",
            // name: "dataInfo",
            component: () =>
              import(
                "../views/Knowledge-processing/Knowledge_serve_list/dataInfo.vue"
              ),
          },
        ],
      },
      {
        path: "/Resource_details",
        name: "Resource_details",
        component: () =>
          import(
            "../views/Resource_transactions/Resource_transactions/Resource_details/index.vue"
          ),
      },
      {
        path: "/Detect", // 跨库检索
        name: "Detect",
        // redirect: "/mianCp",
        component: () => import("../views/crossDatabaseSearch/index.vue"),
        // children: [
        //   {
        //     path: "/mianCp", // 跨库检索 
        //     name: "MianCp",
        //     component: () => import("../views/crossDatabaseSearch/mianCp.vue"),
        //   }
        // ]
      },
      {
        path: "/relation", // ISLI关联资源库
        name: "relation",
        component: () => import("../views/relation/index.vue"),
      },
      {
        path: "/Resource_download", //资源下载
        name: "Resource_download",
        component: () =>
          import(
            "../views/Resource_transactions/Resource_transactions/Resource_download/index.vue"
          ),
      },
      {
        path: "/Release", //上传
        name: "Release",
        component: () =>
          import("../views/academic-headlines/Release/index.vue"),
      },
      {
        path: "/Preview",
        name: "Preview",
        component: () =>
          import("../views/academic-headlines/Preview/index.vue"),
      },
      {
        path: "/Finish", //完成上传
        name: "Finish",
        component: () =>
          import("../views/Resource_transactions/Finish_Uploading/index.vue"),
      },
      {
        path: "/Research", //完成上传
        name: "Research",
        component: () =>
          import("../views/Resource_transactions/Resource_search/index.vue"),
      },
      {
        path: "/Reexpert", //资源达人
        name: "Reexpert",
        component: () =>
          import("../views/Resource_transactions/Resource _expert/index.vue"),
      },
      {
        //搜索
        path: "/search",
        name: "search",
        component: () => import("../views/academic-headlines/search/index.vue"),
        children: [
          {
            path: "/contents", //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
            name: "searchContent",
            component: () =>
              import("../views/academic-headlines/search/article/index.vue"),
          },
          {
            path: "/author", //搜索作者
            name: "searchAuthor",
            component: () =>
              import("../views/academic-headlines/search/author/index.vue"),
          },
          {
            path: "/newsflashs", //搜索快讯
            name: "searchNewsflash",
            component: () =>
              import("../views/academic-headlines/search/news-flash/index.vue"),
          },
          {
            path: "/recommend", //搜索推荐
            name: "searchRecommend",
            component: () =>
              import("../views/academic-headlines/search/recommend/index.vue"),
          },
          {
            path: "/theme", //搜索主题
            name: "searchTheme",
            component: () =>
              import("../views/academic-headlines/search/theme/index.vue"),
          },
        ],
      },
      {
        path: "/authorXX", //作者信息
        // redirect: "/author/article",
        name: "authorXX",
        component: () => import("../components/author/index.vue"),
        children: [
          {
            path: "/articleed", //作者文章
            name: "authorArticle",
            component: () => import("../components/author/article/index.vue"),
          },
          {
            path: "/beCommented", //评论Ta的
            name: "BeCommented",
            component: () =>
              import("../components/author/be-commented/index.vue"),
          },
          {
            path: "/commentTo", //ta评论的
            name: "CommentTo",
            component: () =>
              import("../components/author/comment-to/index.vue"),
          },
          {
            path: "/message", //留言板
            name: "message",
            component: () => import("../components/author/message/index.vue"),
          },
        ],
      },
      {
        path: "/articleDetail", //文章详情
        name: "ArticleDetail",
        component: () => import("../components/article-detail/index.vue"),
      },
      {
        path: "/keywordArticle", //关键词文章
        name: "KeywordArticle",
        component: () => import("../components/keyword-article/index.vue"),
      },
      {
        path: "/newsFlashDetail", //快讯详情
        name: "NewsFlashDetail",
        component: () =>
          import(
            "../views/academic-headlines/common/news-flash-detail/index.vue"
          ),
      },
      {
        path: "/Recommend", //推荐
        name: "Recommend",
        component: () =>
          import("../views/academic-headlines/recommend/index.vue"),
      },
      {
        path: "/team-classification", //团队分类
        name: "team-classification",
        component: () =>
          import("../views/wisdom-answer/index/team-classification/index.vue"),
      },
      {
        path: "/create-a-team", //创建团队
        name: "create-a-team",
        component: () =>
          import("../views/wisdom-answer/index/create-a-team/index.vue"),
      },

      {
        path: "/team-rules", //智能团队创建规则
        name: "team-rules",
        component: () =>
          import("../views/wisdom-answer/index/team-rules/index.vue"),
      },
      {
        path: "/answer-list", //团队成员的回答列表
        name: "answer-list",
        component: () =>
          import("../views/wisdom-answer/index/answer-list/index.vue"),
      },
      {
        path: "/team-details", //智能团队详情
        name: "team-details",
        // redirect: "/whole",
        redirect: "/team-members",
        component: () =>
          import("../views/wisdom-answer/index/team-details/index.vue"),
        children: [
          {
            path: "/team-members", //全部
            name: "/team-members",
            component: () =>
              import(
                "../views/wisdom-answer/index/team-details/team-members/index.vue"
              ),
          },
          {
            path: "/leader", //团长
            name: "/leader",
            component: () =>
              import(
                "../views/wisdom-answer/index/team-details/team-members/leader/index.vue"
              ),
          },
        ],
      },
      {
        path: "/wisdomAnswer", //众智问答
        name: "WisdomAnswer",
        component: () => import("../views/wisdom-answer/index.vue"),
        children: [
          {
            path: "/waIndex", //问答首页
            name: "WaIndex",
            redirect: "/answerIndex",
            component: () => import("../views/wisdom-answer/index/index.vue"),
            children: [
              {
                path: "/answerIndex",
                name: "WaAnswerIndex",
                redirect: "/qaIndex",
                component: () =>
                  import("../views/wisdom-answer/index/main/index.vue"),
                children: [
                  {
                    path: "/qaIndex", //智能问答首页
                    name: "QaIndex",
                    component: () =>
                      import(
                        "../views/wisdom-answer/index/main/qa-index/index.vue"
                      ),
                  },
                  {
                    path: "/allQuestion", //问题全部
                    name: "AllQuestion",
                    component: () =>
                      import(
                        "../views/wisdom-answer/index/all-questions/index.vue"
                      ),
                  },

                  {
                    path: "/recommended", //推荐专家
                    name: "recommended",
                    component: () =>
                      import(
                        "../views/wisdom-answer/index/recommended-experts/index.vue"
                      ),
                  },
                  {
                    path: "/issues", //热点问题
                    name: "issues",
                    component: () =>
                      import(
                        "../views/wisdom-answer/index/hotspot-issues/index.vue"
                      ),
                  },
                ],
              },
              {
                path: "/writeanswer", //写回答
                name: "writeanswer",
                component: () =>
                  import(
                    "../views/wisdom-answer/common/write-answer/index.vue"
                  ),
              },
              {
                path: "/answerDetail", //答案详情页
                name: "AnswerDetail",
                component: () =>
                  import("../views/wisdom-answer/answerDetail/index.vue"),
              },
              {
                path: "/askQuestion", //提问页面
                name: "AskQuestion",
                component: () =>
                  import("../views/wisdom-answer/ask-question/index.vue"),
              },
              {
                path: "/askPreview", //发布
                name: "AskPreview",
                component: () =>
                  import("../views/wisdom-answer/ask-preview/index.vue"),
              },
              {
                path: "/searchAsk", //搜索
                name: "SearchAsk",
                component: () =>
                  import("../views/wisdom-answer/search-ask/index.vue"),
              },
              {
                path: "/previews", //问题预览
                name: "previews",
                component: () =>
                  import("../views/wisdom-answer/preview/index.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/paySuccess", // 充值知识币支付成功提示页面
        name: "paySuccess",
        component: () => import("../components/paySuccess/index.vue"),
      },
      {
        path: "/enterpriseList", // 充值知识币支付成功提示页面
        name: "enterpriseList",
        component: () => import("../components/footer/enterpriseList.vue"),
      },
    ],
  },
  {
    path: "/payGateWay", // 新页面打开支付宝支付窗口
    name: "paySuccess",
    component: () => import("../components/paySuccess/payGateWay.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/academic-headlines/register/index.vue"),
  },
  {
    path: "/change_code", // 未登录 更改密码
    name: "change_code",
    component: () => import("../views/change_code/index.vue"),
  },
  {
    path: "/backManage", //后台管理
    name: "BackManage",
    redirect: "/carouselMain",
    component: () =>
      import("../views/back-manage/academic-headlines/index.vue"),
    children: [
      {
        path: "/carouselMain", //知识头条轮播
        name: "CarouselMain",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/carousel-main/index.vue"
          ),
      },
      {
        path: "/carouselAssistant3", //智能问答轮播
        name: "carouselAssistant3",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/carousel-main/carouselAskQus.vue"
          ),
      },
      {
        path: "/carouselAssistant4", //资源交易轮播
        name: "carouselAssistant4",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/carousel-main/carouselTrade.vue"
          ),
      },
      {
        path: "/addCarouselMain", //新增轮播主图
        name: "AddCarouselMain",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/carousel-main/add/index.vue"
          ),
      },
      {
        path: "/editCarouselMain", //编辑轮播主图
        name: "EditCarouselMain",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/carousel-main/edit/index.vue"
          ),
      },
      {
        path: "/relationCarouselMain", //关联轮播主图
        name: "RelationCarouselMain",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/carousel-main/relation/index.vue"
          ),
      },
      {
        path: "/carouselAssistant", //轮播副图
        name: "CarouselAssistant",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/carousel-assistant/index.vue"
          ),
      },
      {
        path: "/carouselAssistantRelation", //关联轮播副图
        name: "CarouselAssistantRelation",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/carousel-assistant/relation/index.vue"
          ),
      },
      {
        path: "/editCarouselAssistant", //编辑轮播副图
        name: "EditCarouselAssistant",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/carousel-assistant/edit/index.vue"
          ),
      },
      {
        path: "/themeManage", //维护主题
        name: "ThemeManage",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/theme-manage/index.vue"
          ),
      },
      {
        path: "/addTheme", //增加主题
        name: "AddTheme",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/theme-manage/add/index.vue"
          ),
      },
      {
        path: "/editTheme", //编辑主题
        name: "EditTheme",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/theme-manage/edit/index.vue"
          ),
      },
      {
        path: "/themeArticleManage", //主题文章管理
        name: "ThemeArticleManage",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/theme-manage/article/index.vue"
          ),
      },
      {
        path: "/shieldComment", //主题文章管理
        name: "ShieldComment",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/shield-comment/index.vue"
          ),
      }, ///////
      {
        path: "/selected", //精选文章管理
        name: "selected",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/selected-articles/index.vue"
          ),
      },
      // {
      //   path:'/newarticle',//新增文章管理
      //   name:'newarticle',
      //   component: () => import('../views/back-manage/academic-headlines/selected-articles/add/index.vue'),

      // },

      {
        path: "/seearticle", //查看文章管理详情
        name: "seearticle",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/selected-articles/see/index.vue"
          ),
      },
      {
        path: "/modify", //修改文章管理
        name: "modify",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/selected-articles/modify/index.vue"
          ),
      },
      {
        path: "/topics", //精选主题管理
        name: "topics",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/selected-topics/index.vue"
          ),
      },

      {
        path: "/sensitive", //过滤词
        name: "sensitive",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/sensitive-words/index.vue"
          ),
      },
      {
        path: "/words-add", //过滤词添加
        name: "words-add",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/sensitive-words/words-add/index.vue"
          ),
      },

      {
        path: "/modifytheme", //修改主题
        name: "modifytheme",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/selected-topics/modify/index.vue"
          ),
      },
      {
        path: "/addthemesd", //添加主题
        name: "addthemesd",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/selected-topics/add/index.vue"
          ),
      },
      {
        path: "/Resourceaudit", //资源审核
        name: "Resourceaudit",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/Resource-audit/index.vue"
          ),
      },
      {
        path: "/userManage", //用户管理
        name: "userManage",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/user-manage/index.vue"
          ),
      },
      {
        path: "/userAccount", // 用户统计
        name: "userAccount",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/user-account/index.vue"
          ),
      },
      {
        path: "/sortAccount", // 资源统计
        name: "sortAccount",
        component: () =>
          import(
            "../views/back-manage/academic-headlines/sort-account/index.vue"
          ),
      },
      {
        path: "/coordinationFileList", // 协同编辑文件列表
        name: "coordinationFileList",
        component: () =>
          import("../views/back-manage/coordination-edit/file-list/index.vue"),
      },
      {
        path: "/coordinationFileRecord", // 协同编辑文件编辑记录
        name: "coordinationFileRecord",
        component: () =>
          import(
            "../views/back-manage/coordination-edit/file-record/index.vue"
          ),
      },
      {
        path: "/coordinationUploadFile", // 协同编辑上传文件
        name: "coordinationUploadFile",
        component: () =>
          import(
            "../views/back-manage/coordination-edit/upload-file/index.vue"
          ),
      },
      {
        path: "/task", // 一起做业网
        name: "task",
        component: () => import("../views/back-manage/task/index.vue"),
      },
      {
        path: "/Knowledge", // 知识加工后台投诉
        name: "Knowledge",
        component: () => import("../views/back-manage/Knowledge/index.vue"),
      },
    ],
  },
];

export default routes;
