import req from './http.js'
import api from "@/dev/api.ts";

/**
 * 向数据库增加数据
 * @param {*} method 请求方式.get.post.delete.put 
 * @param {*} url    请求url
 * @param {*} insertGraphsqlObj 新增graphsql语句如   ' insert_test(objects: {img: "77", name: "88"}, on_conflict: {constraint: test_pkey, update_columns: img}) {  affected_rows  returning {   img   name  } }'
 */
export const INSERT = function (method, url, insertGraphsqlObj) {
  let url_mr = api.LoginURL.concat(
    "/common/dataBaseUpdate"
  );
  let setParam = {
    'query': 'mutation MyMutation {' + insertGraphsqlObj + '}',
    //  'variables': null,
    'operationName': 'MyMutation'
  }
  return req(method, url_mr, setParam);
}
/**
 * 向数据库增加数据  (未登录)
 * @param {*} method 请求方式.get.post.delete.put 
 * @param {*} url    请求url
 * @param {*} insertGraphsqlObj 新增graphsql语句如   ' insert_test(objects: {img: "77", name: "88"}, on_conflict: {constraint: test_pkey, update_columns: img}) {  affected_rows  returning {   img   name  } }'
 */
export const INSERTS = function (method, url, insertGraphsqlObj) {
  let url_mr = api.LoginURL.concat(
    "/common/dataBaseUpdateByVisitor"
  );
  let setParam = {
    'query': 'mutation MyMutation {' + insertGraphsqlObj + '}',
    //  'variables': null,
    'operationName': 'MyMutation'
  }
  return req(method, url_mr, setParam);
}

/**
 * 修改数据库中的数据
 * @param {*} method   请求方式.get.post.delete.put 
 * @param {*} url      请求url
 * @param {*} updateGraphsqlObj   修改graphql语句如 ' update_ddd(_set: {fff_id: 10, fuck: "", id: 10}, where: {id: {_eq: 10}}) {  affected_rows }'
 */
export const UPDATE = function (method, url, updateGraphsqlObj) {
  let url_mr = api.LoginURL.concat(
    "/common/dataBaseUpdate"
  );
  let setParam = {
    'query': 'mutation MyMutation {' + updateGraphsqlObj + '}',
    'variables': null,
    'operationName': 'MyMutation'
  }
  return req(method, url_mr, setParam);
}

/**未登录修改数据库
 * 修改数据库中的数据
 * @param {*} method   请求方式.get.post.delete.put 
 * @param {*} url      请求url
 * @param {*} updateGraphsqlObj   修改graphql语句如 ' update_ddd(_set: {fff_id: 10, fuck: "", id: 10}, where: {id: {_eq: 10}}) {  affected_rows }'
 */
export const UPDATES = function (method, url, updateGraphsqlObj) {
  let url_mr = api.LoginURL.concat(
    "/common/dataBaseUpdateByVisitor"
  );
  let setParam = {
    'query': 'mutation MyMutation {' + updateGraphsqlObj + '}',
    'variables': null,
    'operationName': 'MyMutation'
  }
  return req(method, url_mr, setParam);
}
/**
 * 删除数据库中的数据
 * @param {*} method   请求方式.get.post.delete.put 
 * @param {*} url      请求url
 * @param {*} deleteGraphsqlObj   删除graphql语句如' delete_test(where: {id: {_eq: 1}}) {  affected_rows }'
 */
export const DELETE = function (method, url, deleteGraphsqlObj) {
  let url_mr = api.LoginURL.concat(
    "/common/dataBaseUpdate"
  );
  let setParam = {
    'query': 'mutation MyMutation {' + deleteGraphsqlObj + '}',
    'variables': null,
    'operationName': 'MyMutation'
  }
  return req(method, url_mr, setParam);
}
/**
 * 查询数据库中的数据
 * @param {*} method  请求方式.get.post.delete.put
 * @param {*} url     请求url
 * @param {*} queryGraphsqlObj  查询graphql语句如'ddd {fff_id fuck id }'
 */
export const QUERY = function (method, url, queryGraphsqlObj) {
  let url_mr = api.LoginURL.concat(
    "/common/search"
  );
  let setParam = {
    'query': 'query MyQuery {' + queryGraphsqlObj + '}',
    'variables': null,
    'operationName': 'MyQuery'
  }
  return req(method, url_mr, setParam);
}

/**
 * 网络服务器dataBaseUpdate接口
 * 查询数据库中的数据未登录
 * @param {*} method   请求方式.get.post.delete.put 
 * @param {*} url      请求url
 * @param {*} queryGraphsqlObj   修改graphql语句如 ' update_ddd(_set: {fff_id: 10, fuck: "", id: 10}, where: {id: {_eq: 10}}) {  affected_rows }'
 */
export const QUERYED = function (method, url, queryGraphsqlObj) {
  let url_mr = api.LoginURL.concat(
    "/common/dataBaseUpdateByVisitor"
  );
  let setParam = {
    'query': 'query MyQuery {' + queryGraphsqlObj + '}',
    'variables': null,
    'operationName': 'MyQuery'
  }
  return req(method, url_mr, setParam);
}