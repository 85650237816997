import {
  DELETE,
  QUERY,
  UPDATE,
  UPDATES
} from "@/services/dao.js";

export const updateread = async function (id, read) {
  // 资源浏览量
  if (read == "NaN") {
    read = 1
  } else {
    read = read * 1 + 1;
  }

  let update_read = await UPDATES("POST", "", 'update_ZYDocument_all(where:{id:{_eq:' + id + '}},_set:{read:"' + read + '"}) {affected_rows}');
  if (update_read.data.update_ZYDocument_all.affected_rows != 1) {
    return console.log(update_read);
  }
}

export const updatedownload = async function (id, download) {
  // 下载量
  download = download * 1 + 1;
  let update_download = await UPDATES("POST", "", 'update_ZYDocument_all(where:{id:{_eq:' + id + '}},_set:{download:"' + download + '"}) {affected_rows}');
  if (update_download.data.update_ZYDocument_all.affected_rows != 1) {
    return console.log(update_download);
  }
}
//补0
export const formatZero = function (num, len) {
  if (String(num).length > len) return num;
  return (Array(len).join(0) + num).slice(-len);
}

export const getTimeFormat = function (time) {
  var b = new Date(time);
  var month = b.getMonth() + 1;
  var strDate = b.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  b = b.getFullYear().toString() + month.toString() + strDate.toString();
  return b;
}
export const getTimeFormat_1 = function (time) {
  var b = new Date(time);
  var month = b.getMonth() + 1;
  var strDate = b.getDate();
  b = b.getFullYear() + "-" + month + "-" + strDate;
  return b;
}