<template>
  <div>
    <Header />

    <Information />

    <div class="contair">
      <router-view />
    </div>
    <foote />
  </div>
</template>

<script>
import Header from "@/components/nav";

import foote from "@/components/footer";
import Information from "@/components/Personal_Information";

export default {
  name: "Home",
  components: {
    Header,
    Information,

    foote,
  },
};
</script>

<style>

</style>
