import 'babel-polyfill' // ie兼容 es6
// require("babel-polyfill");
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "lib-flexible/flexible";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/index.css";
import "@/styles/icon/index.css";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import api from "@/dev/api.ts";
import promise from 'es6-promise';
promise.polyfill() // ie兼容 Promise
import 'ie-placeholder'; // ie兼容css placeholder属性
import md5 from 'js-md5';
// import 'jquery'

const fetch = require('unfetch')
global.Headers = fetch.Headers
global.Request = fetch.Request
global.Response = fetch.Response


Vue.use(VueApollo);
Vue.use(ElementUI);
// 与 API 的 HTTP 连接
const httpLink = createHttpLink({
  // 你需要在这里使用绝对路径
  uri: "http://192.168.1.120:8080/v1/graphql",
  fetch:fetch
});

// 缓存实现
const cache = new InMemoryCache();

// 创建 apollo 客户端
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
Vue.config.productionTip = false;
Vue.prototype.api = api;
Vue.prototype.$md5 = md5;

// window.onresize = setHtmlFontSize;
// function setHtmlFontSize(){
//     const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
//     const htmlDom = document.getElementsByTagName('html')[0];
//     htmlDom.style.fontSize = htmlWidth / 15 + 'px';
// };
// setHtmlFontSize();


new Vue({
  router,
  store,
  apolloProvider,
  data:{
    Hub: new Vue()
  },
  render: (h) => h(App),
}).$mount("#app");
