<template>
  <div class="nav_head">
    <img class="logo" src="../../assets/homeLog.png" />
    <span> 智荟网</span>
    <div class="native_left">
      <el-link @click="native_home" target="_blank" style="color:#CBCBCC"
        >知识头条 </el-link
      >|
      <el-link
        @click="native_wisdomAnswer"
        target="_blank"
        style="color:#CBCBCC"
        >智能问答</el-link
      >|
      <el-link @click="native_resource" target="_blank" style="color:#CBCBCC"
        >资源交易</el-link
      >|
      <el-link @click="native_detect" target="_blank" style="color:#CBCBCC"
        >跨库检索</el-link
      >
      <!-- |
      <el-link @click="native_service" target="_blank" style="color:#CBCBCC"
        >知识服务</el-link
      > -->
      |
      <el-link @click="native_machining" target="_blank" style="color:#CBCBCC"
        >知识加工</el-link
      >
    </div>
    <div class="native">
      <el-link @click="native_news" target="_blank" style="color:#CBCBCC">
        <!-- 登陆后有新消息 -->
        <el-badge v-if="newtrue">
          <img class="news" src="../../assets/tz.png" />
          <p class="newd">new</p>
        </el-badge>
        <!-- 未登录或者没有消息 -->
        <img class="news" v-if="!newtrue" src="../../assets/tz.png" /> </el-link
      >|

      <el-link
        @click="native_login"
        v-show="login"
        target="_blank"
        style="color:#CBCBCC"
        >登录</el-link
      >
      <el-dropdown @command="handleCommand" v-show="user">
        <span class="el-dropdown-link">
          {{ this.$store.state.userinfo.name }}
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="a">个人中心</el-dropdown-item>
          <el-dropdown-item command="b" v-if="admin">后台管理</el-dropdown-item>
          <el-dropdown-item command="c">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { QUERYED, UPDATE } from "@/services/dao";
import {
  updateNews,
  formatDate,
  AcceptNum,
  AcceptNumTeam,
} from "@/js/common/index.js";
export default {
  name: "Header",
  props: {
    msg: String,
  },
  data() {
    return {
      login: true,
      user: false,
      username: "",
      // news: 0, ///消息条数
      newtrue: false, ///是否隐藏
      admin: false,
    };
  },
  created() {
    console.log(this.$store.state.userinfo.name);
    this.username = this.$store.state.userinfo.name;
    if (this.$store.state.userinfo.name !== "") {
      this.login = false;
      this.user = true;
      // this.newtrue=true;
      // this.timer();////调用是否显示 打包释放
    } else {
      this.login = true;
      this.user = false;
      this.newtrue = false; //隐藏消息条数
    }
    if (this.$store.state.userinfo.isAdmin == "0") {
      this.admin = false;
    } else {
      this.admin = true;
    }
  },
  watch: {
    list() {
      // this.timer() // 打包释放
    },
  },
  methods: {
    ////清除定时器
    destroyed() {
      clearInterval(this.timer);
    },
    //  这是一个定时器
    timer() {
      if (
        this.$store.state.userinfo.id != null ||
        this.$store.state.userinfo.id != ""
      ) {
        window.setInterval(() => {
          setTimeout(() => {
            ///调取接口获取数据
            this.newsed();
          }, 0);
        }, 1000); ///每一秒刷新一次
      }
    },

    // 加载消息条数
    async newsed() {
      //如果登陆了用户
      let info = await QUERYED(
        "POST",
        "",
        "  MEssageNotification_aggregate(where: {userid: {_eq: " +
          this.$store.state.userinfo.id +
          "}, identification: {_eq: 0}}) { aggregate {    count  }  }"
      );
      if (info.data.MEssageNotification_aggregate.aggregate.count == 0) {
        ///如果未读条数为0
        this.newtrue = false; //隐藏消息条数
      } else {
        this.newtrue = true; //显示未读条数
      }
    },
    native_home() {
      this.$router.push({ name: "index", params: { userId: 123 } });
    },
    async native_news() {
      this.$router.push({ name: "Notification", params: { userId: 123 } });
      //   /////修改方式为已读
      // let updateNews=await UPDATE("post","",'update_MessageNotification(where: {userid: {_eq: '+this.$store.state.userinfo.id+'}}, _set: {identification : 1}) { affected_rows  }  ');
      //   if(updateNews.data.update_MessageNotification.affected_rows>0){
      //     console.log("修改为已读成功！");
      //     this.newsed();//刷新按钮显示方式
      //   }

    },
    native_login() {
      this.$router.push({ name: "login", params: { userId: 123 } });
    },
    native_PC() {
      this.$router.push({ name: "Personal", params: { userId: 123 } });
    },
    native_wisdomAnswer() {
      this.$router.push({ name: "QaIndex", params: { userId: 123 } });
    },
    native_resource() {
      this.$router.push({ name: "Resource", params: { userId: 123 } });
    },
    native_detect() {
      console.log(456468);
      this.$router.push({ name: "Detect", params: { userId: 123 } });
    },
    native_service() {
      this.$router.push({
        name: "Knowledge",
        params: { userId: 123 },
      });
    },
    native_machining() {
      this.$router.push({ name: "ZSmachining", params: { userId: 123 } });
    },
    handleCommand(command) {
      console.log(command);
      switch (command) {
        case "a":
          this.$router.push({ name: "Personal", params: { userId: 123 } });
          break;
        case "b":
          this.$router.push({ name: "BackManage", params: { userId: 123 } });
          break;
        case "c":
          this.$store.commit("changeLoginUser", "");
          this.$store.commit("changeLogin", "");
          localStorage.removeItem("Authorization");
          this.$router.push({ name: "login", params: { userId: 123 } });
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.nav_head {
  width: 100%;
  height: 70px;
  background-color: #01204f;

  z-index: 999999;
}
.el-dropdown span {
  height: 0 !important;
  font-size: 14px !important;
  float: none !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
}
.nav_head .logo {
  width: 32.25px;
  float: left;
  margin-left: 131.86px;
  margin-top: 18.2px;
}
.news {
  width: 17px;
}
.el-dropdown-link {
  cursor: pointer;
}
.nav_head span {
  height: 24px;
  font-size: 23px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(220, 220, 221, 1);
  float: left;
  margin-left: 15.18px;
  margin-top: 21.82px;
}
.nav_head .native_left {
  float: left;
  height: 15px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(203, 203, 204, 1);
  margin-left: 65px;
  margin-top: 30px;
}
.native_left .el-link {
  font-size: 16px;
  font-weight: 500;
}
.nav_head .native {
  float: right;
  height: 15px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(203, 203, 204, 1);
  margin-right: 100px;
  margin-top: 32px;
}
.newd {
  background: rgb(231, 13, 13);
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
  color: aliceblue;
  padding-bottom: 1px;
  border-radius: 8px;
  position: absolute;
  top: -18px;
  right: 12px;
}
</style>
