import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //搜索框
    info: true,
    Information: false,
    resour: true,
    Authorization: localStorage.getItem("Authorization")
      ? localStorage.getItem("Authorization")
      : "",
    //作者信息组件传值
    authorIntro: {
      img: "", //图片
      author: "", //作者名
      degree: "", //等级
      intro: "", //个人介绍
      articleType: "", //文章类型
      list: [], //文章标题列表
    },
    userinfo: {
      name: "",
      money: 0,
    },
    //左文右图
    linfoRpic: {
      img: "",
      info: "",
      id: "",
    },
    // el_: '',// app.vue实例
  },
  mutations: {
    changeLogin(state, user) {
      state.Authorization = user.Authorization; // 存储 token
      localStorage.setItem("Authorization", user.Authorization);
    },
    changeLoginUser(state, userinfo) {
      state.userinfo = userinfo.userinfo; // 存储 当前登录用户 信息
    },
    changeLoginUserMoney(state,moneyVal){
      state.userinfo.money = moneyVal; // 修改用户知识币
    },
    //搜索框某些页面不显示
    edit(state) {
      state.info = false;
    },
    edit_delete(state) {
      state.info = true;
    },
    edit_Information(state) {
      state.Information = false;
    },
    edit_Information_delete(state) {
      state.Information = true;
    },

    //作者信息组件传值
    alertAuthorIntro(state, authorIntro) {
      state.authorIntro.img = authorIntro.img;
      state.authorIntro.author = authorIntro.author;
      state.authorIntro.degree = authorIntro.degree;
      state.authorIntro.intro = authorIntro.intro;
      state.authorIntro.articleType = authorIntro.articleType;
      state.authorIntro.list = authorIntro.list;
    },
    //左文右图组件
    alertLinfoRpic(state, linfoRpic) {
      state.linfoRpic.img = linfoRpic.img;
      state.linfoRpic.info = linfoRpic.info;
      state.linfoRpic.id = linfoRpic.id;
    },
  },
  actions: {},
  modules: {},
});
