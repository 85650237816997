<template>
  <div class="Information" v-show="this.$store.state.Information">
    <img class="PI" :src="form.Portrait" />
      <div class="Personal_change">
        <span class="Personal_Name">{{ form.name }} </span
        >
        <br/>
        <img src="~@/assets/icon/change.png" />
        <span class="PI_z">修改资料</span>
      </div>
    <div class="zl"><span>{{form.czz}}</span><br/><span class="buttom">成长值</span></div>
    <div class="zl"><span>{{form.czz}}</span><br/><span class="buttom">成长值</span></div>
    <div class="zl"><span>{{form.czz}}</span><br/><span class="buttom">成长值</span></div>
    <div class="zl"><span>{{form.czz}}</span><br/><span  class="buttom">成长值</span></div>
    <div class="zl"><span>{{form.czz}}</span><br/><span class="buttom">成长值</span></div>
    <div class="zl"><span>{{form.czz}}</span><br/><span class="buttom">成长值</span></div>
    <div class="zl"><span>{{form.czz}}</span><br/><span class="buttom">成长值</span></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        Portrait: require("../../assets/img/图层 15.png"),
        name: "未知用户10086",
        czz: "100",
        clz: "1",
        cll: "36",
        rzs: "20",
        bar: "3",
        cfz: "100",
        hyts: "30",
      },
    };
  },
};
</script>

<style scoped>
.Information {
  width: 100%;
  height: 160px;
  background-image: url(~@/assets/img/Vol_005_SE182.png);
  background-size: 100% 100%;
}
.Information .PI {
  width: 109px;
  margin-left: 337px;
  margin-top: 22px;
  float: left;
}
.Personal_change{
  overflow: hidden;
    width: 150px;
    /* margin-top: 60px; */
    /* padding: 10px; */
    padding-top: 60px;
    padding-left: 11px;
    float: left;
}
.Personal_Name {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(78, 77, 77, 1);
  margin-top: 60px;
  margin-left: 0px;
}
.Personal_change img {
  width: 13px;
  margin-top: 26px;
  margin-right: 10px;
}
.PI_z{
  font-size:13px;
font-family:Microsoft YaHei;
font-weight:300;
color:rgba(0,132,255,1);
line-height:47px
}
.zl{
  float: left;
    width: 85px;
    margin-top: 78px;
    font-size:13px;
font-family:Microsoft YaHei;
font-weight:300;
color:rgba(62,62,62,1);
}
.zl .buttom{
  margin-top: 12px;
  position: absolute;
      text-align: center;
    margin-left: -8px;
}
</style>
