/*
 * 请求axios封装
 */
import router from '../router' // 解决 router is not defined
import axios from 'axios'
import api from "@/dev/api.ts";
import {
  Message
} from 'element-ui';
// 创建axios的一个实例 
var instance = axios.create({
  baseURL: '',
  timeout: 10000
})
var that = this
// 一、请求拦截器 忽略
instance.interceptors.request.use(function (config) {
  if (localStorage.getItem('Authorization')) {
    config.headers.token = localStorage.getItem('Authorization');
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});
// 二、响应拦截器 忽略
instance.interceptors.response.use(function (response) {
  // -9 会话过期 -2 校验token失败 -1 请求失败
  if (response.data.respCode == '-2' || response.data.respCode == '-9') {
    Message.error(response.data.respCode == '-2'?'登录信息失效,请重新登录':'登录信息已过期,请重新登录')
    setTimeout(function () {
      router.replace({
        path: '/login',
        // 登录成功后跳入浏览的当前页面
        query: {
          redirect: router.currentRoute.fullPath
        }
      })
    }, 1500)
  }
  if (response.data.respCode == '-1') {
    Message.error('获取数据失败,原因：参数错误')
    
  }
  return response.data;
}, function (error) {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        // 返回 401 清除token信息并跳转到登录页面

        router.replace({
          path: 'login',
          query: {
            redirect: router.currentRoute.fullPath
          }
        })
    }
  }
  // 对响应错误做点什么
  return Promise.reject(error);
});
//响应方法
export default function (method, url, data = null) {
  method = method.toLowerCase();
  if (method === 'post') {
    return instance.post(url, data);
  } else if (method === 'get') {
    return instance.get(url, {
      params: data
    });
  } else if (method === 'delete') {
    return instance.delete(url, {
      params: data
    });
  } else if (method === 'put') {
    return instance.put(url, data);
  } else {
    console.error('未知的method' + method);
    return false;
  }
}

//不要动
export function post(url, data){
  return new Promise((resolve, reject) => {
    instance({
      url:api.LoginURL.concat(url),
      method:'post',
      data
    }).then(res=>{
      resolve(res)
    }).catch(err=>{
      reject(err)
    })
  })
}
//不要动
export function get(url, data){
  return new Promise((resolve, reject) => {
    instance({
      url:api.LoginURL.concat(url),
      method:'get',
      params:data
    }).then(res=>{
      resolve(res)
    }).catch(err=>{
      reject(err)
    })
  })
}