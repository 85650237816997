import Vue from "vue";
import VueRouter, { RawLocation } from "vue-router";
// import actionRouter from "./actione";
import accountRouter from "./account";

Vue.use(VueRouter);
const baseRoutes: any = [];
const routes = baseRoutes.concat(accountRouter);
const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
  } else {
    let token = localStorage.getItem("Authorization");

    if (token === "null" || token === "") {
      next("/login");
    } else {
      next();
    }
  }
});

export default router;
