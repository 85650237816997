<template>
  <div id="app" ref="box">
    <router-view />
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  // mounted(){
  //   var el_ = this.$refs.box;
  //   this.$store.state.el_ = el_; // 将app.vue页面实例存入store
  // },
};
</script>
<style>
#app {
  font-family: Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: auto; /*点击路由成功，但返回后页面无法滚动*/
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.contair {
  overflow: hidden;
  background-color: #f7f7f7;
}
</style>
