<template>
  <div class="footer">
    <!-- <ul>
      <li>
        <span>产品服务</span>
        <span>数字档案馆</span>
        <span>数字化生产</span>
        <span>数字图书馆</span>
        <span>数字博物馆</span>
        <span>销售管理平台</span>
        <span>OA办公系统</span>
        <span>科研项目</span>
      </li>
      <li>
        <span>特色服务</span>
        <span>手机众智</span>
        <span>杂志订阅</span>
        <span>数字出版物订阅</span>
        <span>广告服务</span>
      </li>
      <li>
        <span>合作客户</span>
        <span>合作伙伴</span>
        <span>行业客户</span>
        <span>公司业绩</span>
        <span class="pointer"><router-link to="enterpriseList">企业名录</router-link></span>
      </li>
      <li>
        <span>关于我们</span>
        <span>联系我们</span>
        <span>西信团队</span>
      </li>  
      
      
    </ul> -->
    <div class="fock-us">     
        <span>
          联系电话：023-63500773 通讯地址：重庆市北部新区洪湖西路18号2-1号
        </span>
        <span>
          版权所有：重庆市西信天元数据资讯有限公司
        </span>
         <span>
           <a href="https://beian.miit.gov.cn" target="_blank">备案号：渝ICP备12002444号-6</a>
        </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "foote",
  props: {
    msg: String
  }
};
</script>

<style scoped>
.footer {
  background-color: #252f48;
  padding: 70px 0px;
}
.footer ul{
  width: 80%;
  margin: 0 auto;
  padding-inline-start: 0;
}
.footer ul::after{
  display: block;
  clear: both;
  content: '';
}
.footer ul li{
  width: 25%;
  float: left;
}

.footer ul li span:first-child{
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 1) !important;
  line-height: 31px;
  display: block;
}
.footer ul li span:not(:first-child){
  font-size: 20px;
  font-family: Microsoft YaHei;
  color: #859CD4 !important;
  display: block;
}
.fock-us{
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: #ffffff !important;
  display: block;
  margin-top: 20px;
}
.fock-us span{
  display: block;
  margin-left: 10%;
}
.pointer{
  cursor: pointer;
}
</style>
