import { UPDATE, QUERYED, QUERY, UPDATES } from "@/services/dao.js";
import axios from "axios";
import { formatZero } from "./Resoures";

export const dataFormat = function(time) {
  return `${time.getFullYear()}-${
    time.getMonth() + 1 >= 10
      ? time.getMonth() + 1
      : "0" + (time.getMonth() + 1)
  }-${time.getDate() >= 10 ? time.getDate() : "0" + time.getDate()} ${
    time.getHours() >= 10 ? time.getHours() : "0" + time.getHours()
  }:${time.getMinutes() >= 10 ? time.getMinutes() : "0" + time.getMinutes()}:${
    time.getSeconds() >= 10 ? time.getSeconds() : "0" + time.getSeconds()
  }`;
};
/**
 * 点击增加文章热度 id:文章id hotNum: 文章热度
 */
export const updateHotnumFn = async function(id, hotNum) {
  // console.log("公共加热度方法：")
  // console.log(id,hotNum)
  hotNum += 1;
  let update_hotNum = await UPDATES(
    "POST",
    "",
    "update_TtArticle(where:{id:{_eq:" +
      id +
      "}},_set:{hotNum:" +
      hotNum +
      "}) {affected_rows}"
  );
  if (update_hotNum.data.update_TtArticle.affected_rows != 1) {
    return console.log(update_hotNum);
  }
};
/**
 * 修改消息通知为已读状态  id为消息通知ID
 */
export const updateNews = async function() {
  // let updateNews = await UPDATE("POST", "", '  update_MessageNotification(where: {id: {_eq: '+id+'}}, _set: {identification: 1}) { affected_rows  }');
  // if (updateNews.data.update_MessageNotification.affected_rows == 1) {
  //   return console.log(updateNews);
  // }
  // let updateNews=await UPDATE("post","",'update_MessageNotification(where: {userid: {_eq: '+this.$store.state.userinfo.id+'}}, _set: {identification : 1}) { affected_rows  }  ');
  // if(updateNews.data.update_MessageNotification.affected_rows>0){
  //   console.log("修改为已读成功！")
  // }
};

// /**
//  * 根据用户的ID生成的订单号
//  */
// export const updateNews = async function () {
//   // let updateNews = await UPDATE("POST", "", '  update_MessageNotification(where: {id: {_eq: '+id+'}}, _set: {identification: 1}) { affected_rows  }');
//   // if (updateNews.data.update_MessageNotification.affected_rows == 1) {
//   //   return console.log(updateNews);
//   // }
//   // let updateNews=await UPDATE("post","",'update_MessageNotification(where: {userid: {_eq: '+this.$store.state.userinfo.id+'}}, _set: {identification : 1}) { affected_rows  }  ');
//   // if(updateNews.data.update_MessageNotification.affected_rows>0){
//   //   console.log("修改为已读成功！")
//   // }
// }
////计算用户的采纳数和回答数         +1//////回答的ID
export const AcceptNum = async function(id) {
  // let num=0;
  ////查找该回答的采纳数
  // let data=await QUERYED("POST","",'  WDAnswerRef(where: {id: {_eq: '+id+'}}) {  AcceptNum }')
  // 采纳数+1
  //  num=data.data.WdAnswerRef[0].AcceptNum+1;
  //   let updateNews = await UPDATE("POST", "", '  update_WdAnswerRef(where: {id: {_eq: '+id+'}}, _set: {AcceptNum: '+num+'}) { affected_rows  }');
  //   if (updateNews.data.update_WdAnswerRef.affected_rows == 1) {
  //     return console.log(updateNews);
  //   }
  ///根据回答ID查找该回答者的回答数
  let dataHD = await QUERYED(
    "post",
    "",
    " WDAnswerRef_aggregate(where: {user: {_eq: " +
      id +
      "}}) {  aggregate {  count   } }"
  );
  ///根据回答ID查找该回答者的采纳数
  let dataCN = await QUERYED(
    "post",
    "",
    " WDAnswerRef_aggregate(where: {user: {_eq: " +
      id +
      "}, adopt: {_eq: 1}}) {  aggregate {  count   } }"
  );
  // 修改用户的采纳数
  let updateNews = await UPDATE(
    "POST",
    "",
    "  update_User(where: {id: {_eq: " +
      id +
      "}}, _set: {wdAcceptNum : " +
      dataCN.data.WDAnswerRef_aggregate.aggregate.count +
      "}) { affected_rows  }"
  );
  //  回答数
  let updateAnswers = await UPDATE(
    "POST",
    "",
    "  update_User(where: {id: {_eq: " +
      id +
      "}}, _set: {wdAnswerNum  : " +
      dataHD.data.WDAnswerRef_aggregate.aggregate.count +
      "}) { affected_rows  }"
  );
  if (
    updateNews.data.update_User.affected_rows == 1 &&
    updateAnswers.data.update_User.affected_rows == 1
  ) {
    console.log("修改用户回答数和采纳数成功！");
  } else {
    return console.log(updateNews + "," + updateAnswers);
  }
};
////计算团队的采纳数和回答数         +1//////回答的ID
export const AcceptNumTeam = async function(id) {
  // 根据用户ID查询该用户是否加入了团队
  let TeamUser = await QUERYED(
    "post",
    "",
    "WDTeamMember(where: {userid: {_eq: " + id + "}}) { team  }"
  );
  if (TeamUser.data.WDTeamMember.length > 0) {
    ///如果后加入了团队
    for (let i = 0; i < TeamUser.data.WDTeamMember.length; i++) {
      //  ///通过团队ID统计该团队的回答数
      // (where: {id: {_eq: '+TeamUser.data.WDTeamMember[i].team+'}, adopt: {_eq: 1}})
      let dataAn = await QUERYED(
        "post",
        "",
        "WDMemberTeamWdAnswerRef_aggregate(where: {id: {_eq: " +
          TeamUser.data.WDTeamMember[i].team +
          "}}) { aggregate {   count }  }"
      );
      ///通过ID统计该团队的采纳数
      let dataAc = await QUERYED(
        "post",
        "",
        "WDMemberTeamWdAnswerRef_aggregate(where: {id: {_eq: " +
          TeamUser.data.WDTeamMember[i].team +
          "}, adopt: {_eq: 1}})  { aggregate {   count  }  }"
      );
      // 修改团队的回答数
      let update_UP = await UPDATE(
        "post",
        "",
        "update_WdTeam(where: {id: {_eq: " +
          TeamUser.data.WDTeamMember[i].team +
          "}}, _set: {anserNum: " +
          dataAn.data.WDMemberTeamWdAnswerRef_aggregate.aggregate.count +
          "}) { affected_rows  }"
      );
      // 修改团队的采纳数
      let update_UPs = await UPDATE(
        "post",
        "",
        "update_WdTeam(where: {id: {_eq: " +
          TeamUser.data.WDTeamMember[i].team +
          "}}, _set: {acceptNum: " +
          dataAc.data.WDMemberTeamWdAnswerRef_aggregate.aggregate.count +
          "}) { affected_rows  }"
      );
      if (
        update_UP.data.update_WdTeam.affected_rows == 1 &&
        update_UPs.data.update_WdTeam.affected_rows == 1
      ) {
        console.log("修改团队回答数和采纳数成功！");
      }
    }
  }
};
export const SensitiveWords = async function(name, title) {
  axios
    .post(
      "http://39.100.48.36:3016/getData",
      {
        content: name,
      },
      {
        headers: {
          "Content-Type": "application/json",
          //  "token":localStorage.getItem("Authorization")
        },
      }
    )
    // )
    .then((response) => {
      console.log(
        "敏感词过滤：" + JSON.stringify(response.data.message.filterContent)
      );
      title = response.data.message.filterContent;
    })
    .catch(function(error) {
      console.log("错误" + error);
    });
};
/**
 * //////////////////积分规则/////////////+1
 */
///////////////加一分
export const integralAddOne = async function(id) {
  let totals = await QUERYED(
    "post",
    "",
    "   PCUsers(where: {id: {_eq: " + id + "}}) {  integral  }"
  );
  totals.data.PCUsers[0].integral += 1;
  let data = await UPDATE(
    "post",
    "",
    "   update_User(where: {id: {_eq: " +
      id +
      "}}, _set: {integral: " +
      totals.data.PCUsers[0].integral +
      "}) {   affected_rows  }"
  );
  if (data.data.update_User.affected_rows == 1) {
    console.log("积分" + totals.data.PCUsers[0].integral);
  }
};
///////////////加两分
export const integralAddTwo = async function(id) {
  let totals = await QUERYED(
    "post",
    "",
    "   PCUsers(where: {id: {_eq: " + id + "}}) {  integral  }"
  );
  // totals.data.PCUsers[0].integral  += 2;
  let data = await UPDATE(
    "post",
    "",
    "   update_User(where: {id: {_eq: " +
      id +
      "}}, _set: {integral: " +
      (totals.data.PCUsers[0].integral * 1 + 2) +
      "}) {   affected_rows  }"
  );
  if (data.data.update_User.affected_rows == 1) {
    console.log("积分", totals.data.PCUsers[0].integral * 1 + 2);
  }
};
///////////////加三分
export const integralAddThree = async function(id) {
  let totals = await QUERYED(
    "post",
    "",
    "   PCUsers(where: {id: {_eq: " + id + "}}) {  integral  }"
  );
  totals.data.PCUsers[0].integral += 3;
  let data = await UPDATE(
    "post",
    "",
    "   update_User(where: {id: {_eq: " +
      id +
      "}}, _set: {integral: " +
      totals.data.PCUsers[0].integral +
      "}) {   affected_rows  }"
  );
  if (data.data.update_User.affected_rows == 1) {
    console.log("积分" + totals.data.PCUsers[0].integral);
  }
};
///////////////加五分
export const integralAddFive = async function(id) {
  let totals = await QUERYED(
    "post",
    "",
    "   PCUsers(where: {id: {_eq: " + id + "}}) {  integral  }"
  );
  totals.data.PCUsers[0].integral += 5;
  let data = await UPDATE(
    "post",
    "",
    "   update_User(where: {id: {_eq: " +
      id +
      "}}, _set: {integral: " +
      totals.data.PCUsers[0].integral +
      "}) {   affected_rows  }"
  );
  if (data.data.update_User.affected_rows == 1) {
    console.log("积分" + totals.data.PCUsers[0].integral);
  }
};
///////////////加十分
export const integralAddTen = async function(id) {
  let totals = await QUERYED(
    "post",
    "",
    "   PCUsers(where: {id: {_eq: " + id + "}}) {  integral  }"
  );
  totals.data.PCUsers[0].integral += 10;
  let data = await UPDATE(
    "post",
    "",
    "   update_User(where: {id: {_eq: " +
      id +
      "}}, _set: {integral: " +
      totals.data.PCUsers[0].integral +
      "}) {   affected_rows  }"
  );
  if (data.data.update_User.affected_rows == 1) {
    console.log("积分" + totals.data.PCUsers[0].integral);
  }
};
/**
 * 将 Thu May 12 2019 08:00:00 GMT+0800 (中国标准时间) 格式改为 2019-5-12 08:00:00
 *
 */
export const showTimeFormat = function(time) {
  let b = new Date(time);
  if (b.toString() === "Invalid Date") {
    b = new Date(time * 1);
  }
  b = `${b.getFullYear()}-${
    b.getMonth() + 1 >= 10 ? b.getMonth() + 1 : "0" + (b.getMonth() + 1)
  }-${b.getDate() >= 10 ? b.getDate() : "0" + b.getDate()}
     ${b.getHours() >= 10 ? b.getHours() : "0" + b.getHours()}:${
    b.getMinutes() >= 10 ? b.getMinutes() : "0" + b.getMinutes()
  }:${b.getSeconds() >= 10 ? b.getSeconds() : "0" + b.getSeconds()}`;
  return b;
};
/**
 * 将 Thu May 12 2019 08:00:00 GMT+0800 (中国标准时间) 格式改为 年月日
 *
 */
export const showTimeYearDate = function(time) {
  let b = new Date(time);
  if (b.toString() === "Invalid Date") {
    b = new Date(time * 1);
  }
  b = `${b.getFullYear()}-${
    b.getMonth() + 1 >= 10 ? b.getMonth() + 1 : "0" + (b.getMonth() + 1)
  }-${b.getDate() >= 10 ? b.getDate() : "0" + b.getDate()}`;
  return b;
};
///年份
export const showTimeYear = function(time) {
  var b = new Date(time);
  b = `${b.getFullYear()}`;
  return b;
};
/**
 * @description 2020-12-1
 * @params {number}
 */
export function showTimeFormat2(time) {
  var b = new Date(time);
  b = `${b.getFullYear()}-${
    b.getMonth() + 1 >= 10 ? b.getMonth() + 1 : "0" + (b.getMonth() + 1)
  }-${b.getDate() >= 10 ? b.getDate() : "0" + b.getDate()}`;
  return b;
}

export const getTimeFormat = function(time) {
  var b = new Date(time);
  // b = b.getMonth() + 1 + "-" + b.getDate();
  b = b.getMonth() + 1 + "/" + b.getDate();
  return b;
};

export const getYMD = function(time) {
  var b = new Date(time);
  let Y = b.getFullYear();
  let M = b.getMonth() + 1;
  let D = b.getDate();
  return Y + "/" + M + "/" + D;
  // b = b.getMonth() + 1 + "/" + b.getDate();
  // return b;
};
/**
 * @desc 格式化日期字符串
 * @param { Nubmer} - 时间戳
 * @returns { String } 格式化后的日期字符串 如几分钟前，几小时前，几天前。。
 */
export function formatDate(timestamp) {
  // 补全为13位
  let arrTimestamp = (timestamp + "").split("");
  for (let start = 0; start < 13; start++) {
    if (!arrTimestamp[start]) {
      arrTimestamp[start] = "0";
    }
  }
  timestamp = arrTimestamp.join("") * 1;
  let minute = 1000 * 60;
  let hour = minute * 60;
  let day = hour * 24;
  let month = day * 30;
  let now = new Date().getTime();
  let diffValue = now - timestamp;
  // 如果本地时间反而小于变量时间
  if (diffValue < 0) {
    return "不久前";
  }
  // 计算差异时间的量级
  let monthC = diffValue / month;
  let weekC = diffValue / (7 * day);
  let dayC = diffValue / day;
  let hourC = diffValue / hour;
  let minC = diffValue / minute;
  // 数值补0方法
  let zero = function(value) {
    if (value < 10) {
      return "0" + value;
    }
    return value;
  };
  // 使用
  if (monthC > 4) {
    // 超过1年，直接显示年月日
    return (function() {
      let date = new Date(timestamp);
      return (
        date.getFullYear() +
        "年" +
        zero(date.getMonth() + 1) +
        "月" +
        zero(date.getDate()) +
        "日"
      );
    })();
  } else if (monthC >= 1) {
    return parseInt(monthC) + "月前";
  } else if (weekC >= 1) {
    return parseInt(weekC) + "周前";
  } else if (dayC >= 1) {
    return parseInt(dayC) + "天前";
  } else if (hourC >= 1) {
    return parseInt(hourC) + "小时前";
  } else if (minC >= 1) {
    return parseInt(minC) + "分钟前";
  }
  return "刚刚";
}

/**
 * @desc 格式化日期字符串
 * @param { Nubmer} - 时间戳
 * @returns { String } 格式化后的日期字符串 如几分钟内，几小时内，几天内。。
 */
export function format(timestamp) {
  // 补全为13位
  let arrTimestamp = (timestamp + "").split("");
  for (let start = 0; start < 13; start++) {
    if (!arrTimestamp[start]) {
      arrTimestamp[start] = "0";
    }
  }
  timestamp = arrTimestamp.join("") * 1;
  let minute = 1000 * 60;
  let hour = minute * 60;
  let day = hour * 24;
  let month = day * 30;
  let now = new Date().getTime();
  let diffValue = now - timestamp;
  // 如果本地时间反而小于变量时间
  if (diffValue < 0) {
    return "不久前";
  }
  // 计算差异时间的量级
  let monthC = diffValue / month;
  let weekC = diffValue / (7 * day);
  let dayC = diffValue / day;
  let hourC = diffValue / hour;
  let minC = diffValue / minute;
  // 数值补0方法
  let zero = function(value) {
    if (value < 10) {
      return "0" + value;
    }
    return value;
  };
  // 使用
  if (monthC > 4) {
    // 超过1年，直接显示年月日
    return (function() {
      let date = new Date(timestamp);
      return (
        date.getFullYear() +
        "年" +
        zero(date.getMonth() + 1) +
        "月" +
        zero(date.getDate()) +
        "日"
      );
    })();
  } else if (monthC <= 1) {
    return parseInt(monthC) + "月内";
  } else if (weekC <= 1) {
    return parseInt(weekC) + "周内";
  } else if (dayC <= 1) {
    return parseInt(dayC) + "天内";
  } else if (hourC <= 1) {
    return parseInt(hourC) + "小时内";
  } else if (minC <= 1) {
    return parseInt(minC) + "分钟内";
  }
  return "刚刚";
}

/**
 * 生成唯一订单号 雪花算法
 */
//生成唯一订单号  雪花算法
// export const bigInOrderNo = () => {
//   var Snowflake = /** @class */ (function () {
//     function Snowflake(_workerId, _dataCenterId, _sequence) {
//       this.twepoch = 1288834974657n;
//       //this.twepoch = 0n;
//       this.workerIdBits = 5n;
//       this.dataCenterIdBits = 5n;
//       this.maxWrokerId = -1n ^ (-1n << this.workerIdBits); // 值为：31
//       this.maxDataCenterId = -1n ^ (-1n << this.dataCenterIdBits); // 值为：31
//       this.sequenceBits = 12n;
//       this.workerIdShift = this.sequenceBits; // 值为：12
//       this.dataCenterIdShift = this.sequenceBits + this.workerIdBits; // 值为：17
//       this.timestampLeftShift = this.sequenceBits + this.workerIdBits + this.dataCenterIdBits; // 值为：22
//       this.sequenceMask = -1n ^ (-1n << this.sequenceBits); // 值为：4095
//       this.lastTimestamp = -1n;
//       //设置默认值,从环境变量取
//       this.workerId = 1n;
//       this.dataCenterId = 1n;
//       this.sequence = 0n;
//       if (this.workerId > this.maxWrokerId || this.workerId < 0) {
//         throw new Error('_workerId must max than 0 and small than maxWrokerId-[' + this.maxWrokerId + ']');
//       }
//       if (this.dataCenterId > this.maxDataCenterId || this.dataCenterId < 0) {
//         throw new Error('_dataCenterId must max than 0 and small than maxDataCenterId-[' + this.maxDataCenterId + ']');
//       }

//       this.workerId = BigInt(_workerId);
//       this.dataCenterId = BigInt(_dataCenterId);
//       this.sequence = BigInt(_sequence);
//     }
//     Snowflake.prototype.tilNextMillis = function (lastTimestamp) {
//       var timestamp = this.timeGen();
//       while (timestamp <= lastTimestamp) {
//         timestamp = this.timeGen();
//       }
//       return BigInt(timestamp);
//     };
//     Snowflake.prototype.timeGen = function () {
//       return BigInt(Date.now());
//     };
//     Snowflake.prototype.nextId = function () {
//       var timestamp = this.timeGen();
//       if (timestamp < this.lastTimestamp) {
//         throw new Error('Clock moved backwards. Refusing to generate id for ' +
//           (this.lastTimestamp - timestamp));
//       }
//       if (this.lastTimestamp === timestamp) {
//         this.sequence = (this.sequence + 1n) & this.sequenceMask;
//         if (this.sequence === 0n) {
//           timestamp = this.tilNextMillis(this.lastTimestamp);
//         }
//       } else {
//         this.sequence = 0n;
//       }
//       this.lastTimestamp = timestamp;
//       return ((timestamp - this.twepoch) << this.timestampLeftShift) |
//         (this.dataCenterId << this.dataCenterIdShift) |
//         (this.workerId << this.workerIdShift) |
//         this.sequence;
//     };
//     return Snowflake;
//   }());
//   var tempSnowflake = new Snowflake(1n, 1n, 0n);
//   var orderNo = tempSnowflake.nextId();
//   // console.log(orderNo,'tempSnowflake.nextId()');
//   // console.log(orderNo+'','tempSnowflake.nextId()')
//   return orderNo + ''
// }

export function DateAdd(interval, number, date) {
  /*
   *   功能:实现VBScript的DateAdd功能.
   *   参数:interval,字符串表达式，表示要添加的时间间隔.
   *   参数:number,数值表达式，表示要添加的时间间隔的个数.
   *   参数:date,时间对象.
   *   返回:新的时间对象.
   *   var   now   =   new   Date();
   *   var   newDate   =   DateAdd( "d ",5,now);
   *---------------   DateAdd(interval,number,date)   -----------------
   */
  console.log("DateAdd: ", interval);
  switch (interval) {
    case "y ": {
      date.setFullYear(date.getFullYear() + number);
      return date;
      break;
    }
    case "q ": {
      date.setMonth(date.getMonth() + number * 3);
      return date;
      break;
    }
    case "m ": {
      date.setMonth(date.getMonth() + number);
      return date;
      break;
    }
    case "w ": {
      date.setDate(date.getDate() + number * 7);
      return date;
      break;
    }
    case "d ": {
      date.setDate(date.getDate() + number);
      return date;
      break;
    }
    case "h ": {
      date.setHours(date.getHours() + number);
      return date;
      break;
    }
    case "min ": {
      date.setMinutes(date.getMinutes() + number);
      return date;
      break;
    }
    case "s ": {
      date.setSeconds(date.getSeconds() + number);
      return date;
      break;
    }
    default: {
      date.setDate(d.getDate() + number);
      return date;
      break;
    }
  }
}

/**
 * @description js乘法
 * @params (number,number)
 * @returns number
 */
export function Solution(a, b) {
  if (a === 0 || b === 0) return 0; //

  let m = 0; //精度
  let s1 = a.toString();
  let s2 = b.toString();

  if (s1.indexOf(".") == -1 && s2.indexOf(".") == -1) {
    return a * b;
  } else {
    //计算s1小数点后的位数
    m = m + (s1.indexOf(".") >= 0 ? s1.split(".")[1].length : 0);
    //计算s2小数点后的位数
    m = m + (s2.indexOf(".") >= 0 ? s2.split(".")[1].length : 0);
    //除小数点后的所有位数相乘/精度
    return (
      (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
      Math.pow(10, m)
    );
  }
}

/**
 * @description 减法
 * @param {Number} arg1
 * @param {Number} arg2
 */
export const subtr = (arg1, arg2) => {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  //last modify by deeka
  //动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
};

/**
 * @description 加法
 * @param {Number} arg1
 * @param {Number} arg2
 */
export const accAdd = (arg1, arg2) => {
  var r1, r2, m;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  return (arg1 * m + arg2 * m) / m;
};

/**
 * @description 四舍五入保留2位小数（不够位数，则用0替补）
 * @param {Number string} num
 */
export function keepTwoDecimalFull(num) {
  console.log("保留2位小数被调");
  var result = parseFloat(num);
  if (isNaN(result)) {
    //alert('传递参数错误，请检查！');
    this.$message({
      message: "传递参数错误，请检查！",
      type: "warning",
    });
    return false;
  }
  result = Math.round(num * 100) / 100;
  var s_x = result.toString();
  var pos_decimal = s_x.indexOf(".");
  if (pos_decimal < 0) {
    pos_decimal = s_x.length;
    s_x += ".";
  }
  while (s_x.length <= pos_decimal + 2) {
    s_x += "0";
  }
  return s_x;
}

//订单号生成
export async function Bringbill() {
  let orderNum = "";
  let num = String(formatZero(this.$store.state.userinfo.id, 7));
  let orderlist = await QUERYED(
    "post",
    "",
    "  ALLBill(where: {userid: {_eq: " +
      this.$store.state.userinfo.id +
      "}}) {     time  orderNum   }"
  );
  if (orderlist.data.ALLBill.length < 1) {
    orderNum = getTimeFormat(new Date()) + num + "001";
  } else {
    for (let i = 0; i < orderlist.data.ALLBill.length; i++) {
      if (
        getTimeFormat(new Date()) ==
        getTimeFormat(orderlist.data.ALLBill[i].time)
      ) {
        let sic = orderlist.data.ALLBill[0].orderNum.substr(-3) * 1 + 1;

        orderNum = getTimeFormat(new Date()) + num + String(formatZero(sic, 3));
        console.log("2", orderNum);
        break;
      } else {
        orderNum = getTimeFormat(new Date()) + num + "001";
      }
    }
  }
  return orderNum;
}
//时间格式转换(时间戳)
export function dateType(value) {
  let date = new Date(value);
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? "0" + MM : MM;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let m = date.getMinutes();
  m = m < 10 ? "0" + m : m;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
}
//时间格式转换(时间戳)(年月日)
export function YearMMdd(value) {
  let date = new Date(value);
  if (date.toString() === "Invalid Date") {
    date = new Date(value * 1);
  }
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? "0" + MM : MM;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let m = date.getMinutes();
  m = m < 10 ? "0" + m : m;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  return y + "-" + MM + "-" + d;
}
